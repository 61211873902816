import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TimerCountDown = ({ initialTime }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    // Display the toast
    const toastId = toast.info(
      <div>
        Time left: {`${Math.floor(time / 60)}`.padStart(2, "0")}:
        {`${time % 60}`.padStart(2, "0")}
      </div>,
      {
        position: "top-right",
        autoClose: false, // Keep toast open until manually closed
        hideProgressBar: true,
        pauseOnHover: false,
        closeButton: true,
        style: {
          background: "#061731",
          color: "#FFFF",
        },
      }
    );

    // Update the toast every second
    const timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          toast.update(toastId, {
            render: "Time's up!",
            type: "info",
            autoClose: 5000, // Auto-close after 5 seconds
          });
          return 0;
        } else {
          toast.update(toastId, {
            render: (
              <div>
                Time left: {`${Math.floor(time / 60)}`.padStart(2, "0")}:
                {`${time % 60}`.padStart(2, "0")}
              </div>
            ),
            type: "info",
          });
          return time - 1;
        }
      });
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(timer);
  }, [initialTime]);

  return null; // No need to render anything
};

export default TimerCountDown;
